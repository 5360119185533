import React, { useState, useEffect } from "react"
import { Link, navigate } from 'gatsby'
import Logo from '../components/Logo'
import { ChevronDown, ChevronUp } from '@geist-ui/react-icons'

export default function MenuDropdown () {

const [showcollections, setShowCollections] = useState()
const [showtraining, setShowTraining] = useState()
const [showoverlay, setShowOverlay] = useState()

  useEffect(() => {
     if(showcollections || showtraining) {
         setShowOverlay(true);
     }
  }, [showcollections, showtraining]);

  function closeoverlay () {
      setShowOverlay(false);
      setShowCollections(false);
      setShowTraining(false);
  }

  return (
      <>
    {showoverlay && <div className="overlay" onClick={() => closeoverlay()}/>}
    <div className="top-logo">
        <Logo />
    </div>
    </>
  )
}